<template>
  <SimpleModal :data="errorModalData" />

  <FormKit
    :id="formId"
    type="form"
    :actions="false"
    :errors="errors"
    validation-visibility="submit"
    @submit="doLogin"
  >
    <OverlaySpinner
      v-if="spinner.isLoading()"
      :scoped="true"
    />

    <FormKitMessages />

    <FormKit
      type="text"
      name="username"
      label="Username"
      validation="required"
    />

    <FormKit
      type="password"
      name="password"
      label="Password"
      validation="required"
    />

    <template #actions>
      <BaseActions
        :form-id="formId"
        :show-cancel="false"
      >
        <router-link
          class="reset"
          :to="{name:'reset-password'}"
        >
          Reset password
        </router-link>
      </BaseActions>
    </template>
  </FormKit>
</template>

<script setup lang="ts">
// Also look to https://programsdream.nl/blog/rest-api-decoupled-drupal-8-user-registration-confirmation
import { isLeft } from 'fp-ts/Either';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { useRoute, useRouter } from 'vue-router';
import { FormKit, FormKitMessages } from '@formkit/vue';
import { useGtm } from '@gtm-support/vue-gtm';
import { useAuthStore } from '~/store/auth';
import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import { useCartApi } from '~/composables/api/commerce/cart';
import { errorResolver } from '~/composables/error-resolver';
import { useDrupalJsonApi } from '~/composables/api/drupal-json-api';
// @ts-ignore
import { useLoadingState } from '~/composables/states/loading-state';
import { EitherAxiosResponseData } from '~/composables/api/api-common';
import { AuthResponse } from '~/composables/api/oauth';
import { useModalData } from '~/composables/modal';
import { getDestination } from '~/common/path';
import { useStreamChatStore } from '~/store/stream-chat';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { DrupalRole } from '~/types/drupal-common';

const authStore = useAuthStore();
const drupalRestApi = useDrupalRestApi();
const drupalJsonApi = useDrupalJsonApi();
const cartApi = useCartApi();
const route = useRoute();
const router = useRouter();
const spinner = useLoadingState();
const modalData = useModalData();
const streamChatStore = useStreamChatStore();
const apiBaseUrlStore = useApiBaseUrlStore();
const gtm = useGtm();

const formId = 'login-form';

const errors = ref<string[]>([]);
const errorModalData = modalData.errorModal;
const emit = defineEmits(['submit', 'done']);

const fireDone = () => {
  emit('done');
  spinner.end();
};

// https://dev.acquia.com/blog/decoupled-drupal-authentication-oauth-20
const doLogin = async (data: { username: string, password: string }) => {
  emit('submit');
  spinner.start();
  const {
    username,
    password,
  } = data;
  if (!authStore) {
    fireDone();
    return;
  }

  const preLoginCartToken = await cartApi.getCartToken();
  const eitherCarts = await cartApi.getCarts(preLoginCartToken);

  if (isLeft(eitherCarts)) {
    errors.value = ['We encountered an unexpected error. Please try again later.'];
    console.log('there was an error grabbing the carts before login.');
    errorResolver.logError(eitherCarts.left);
    fireDone();
    return;
  }

  const eitherResponse: EitherAxiosResponseData<AuthResponse> = await authStore.login(username, password);
  if (isLeft(eitherResponse)) {
    const statusCode = 'response' in eitherResponse.left
      ? eitherResponse.left.response?.status
      : undefined;
    errors.value = statusCode === 400
      ? ['Invalid username or password.']
      : ['We encountered an unexpected error. Please try again later.'];
    fireDone();
    return;
  }

  const eitherAuthHeader = await authStore.getAuthorizationHeader();
  if (isLeft(eitherAuthHeader)) {
    errors.value = ['We encountered an unexpected error. Please try again later.'];
    fireDone();
    return;
  }

  if (!authStore.roles.includes(DrupalRole.ADMINISTRATOR)) {
    gtm?.push({
      event: 'login',
      roles: authStore.roles,
    });
  }

  const cartsCount = eitherCarts.right.data.length;
  if (cartsCount > 0) {
    // console.log('assigning the anon cart')
    const eitherAssignCarts = await cartApi.reassignAnonCarts(preLoginCartToken, authStore.accessToken);
    // console.log(preLoginCartToken, authStore.accessToken);
    if (isLeft(eitherAssignCarts)) {
      // console.log(authStore.accessToken)
      errorResolver.logError(eitherAssignCarts.left);
      fireDone();

      errorModalData.body = '<p>There was an error processing your shopping cart. Please <a href=\'/contact\'>contact support</a> if you run into any issues.</p>';
      errorModalData.state.open();
      await errorModalData.state.onClose();
    }
  }

  const eitherMergedResponse = await cartApi.mergeUserCarts(eitherAuthHeader.right);

  if (isLeft(eitherMergedResponse)) {
    // For an undetermined reason, sometimes this will fail
    // the first time it is called. Call it a second time
    // just in case to ensure merges. We can bail if it fails
    // this second time.
    const eitherMergedResponse2 = await cartApi.mergeUserCarts(eitherAuthHeader.right);
    if (isLeft(eitherMergedResponse2)) {
      errorResolver.logError(eitherMergedResponse.left);
      fireDone();

      errorModalData.body = '<p>There was an error processing your shopping cart. Please <a href=\'/contact\'>contact support</a> if you run into an issues.</p>';
      errorModalData.state.open();
      await errorModalData.state.onClose();
      return;
    }
    // console.log('2 succeeds')
  }

  streamChatStore.connectUser;

  const initialDestination = getDestination(route);
  const destination = initialDestination?.startsWith('/') || initialDestination?.startsWith(apiBaseUrlStore.apiBaseUrl)
    ? initialDestination
    : '/';

  if (destination.startsWith('/')) {
    router.push(destination)
      .then(async () => {
        cartApi.getCarts(await cartApi.getCartToken());
      });
  } else {
    await cartApi.getCarts(await cartApi.getCartToken());
    window.location = destination;
  }

  fireDone();
};

export interface RequestUrlComponents {
  entityType: string,
  bundle?: string,
  uuid?: string,
  params?: DrupalJsonApiParams,
  isCommerce?: boolean,
  trailing?: string | string[]
}
</script>

<style lang="scss" scoped>
:deep(.actions) {
  @media(max-width: 400px) {
    > * {
      display: block;
    }

    .reset {
      margin-top: .5em;
    }
  }
}
</style>
